@import url(https://fonts.googleapis.com/css?family=PT+Sans+Narrow:400,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Global */

html,
body {
  background-color: #4da29f;
  font-family: "PT Sans Narrow", sans-serif;
  height: 100%;
  overflow: hidden;
}

/* Body of app */
.App {
  background-color: #4da29f;
  background-image: url(/static/media/background.2ac2a6a6.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
}

.App .about {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(44, 100, 98, 0.9);
  opacity: 0;
  z-index: -1;
  transition: z-index 0.25s step-end, opacity 0.25s ease-in-out;
}

.App .about .content {
  width: 320px;
  overflow-y: scroll;
  text-align: left;
  padding: 16px;
}

.App .about blockquote {
  font-style: italic;
}
.App .about button {
  margin-top: 32px;
  text-align: center;
  margin-bottom: 32px;
  width: 320px;
}

.App .about.visible {
  opacity: 1;
  z-index: 100;
  transition: z-index 0.25s step-start, opacity 0.25s ease-in-out;
}

.App button {
  font-family: "PT Sans Narrow", sans-serif;
  font-weight: 700;
  border-radius: 8px;
  background-color: rgba(77, 162, 159, 1);
  max-height: 80px;
  width: 320px;
  border: none;
  color: #fff;
  font-size: 24px;
  padding: 8px 16px;
}

/* Forget Not Logo */
.logo-forget-not {
  background-image: url(/static/media/forget-not.9908c1c5.png);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  height: 360px;
  max-width: 1200px;
  width: 80%;
  transition: all 0.25s ease-out;
  margin-bottom: 32px;
  opacity: 0;
  margin-top: 0;
}

.App.home .logo-forget-not {
  margin-top: 64px;
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.App.promise .logo-forget-not {
  opacity: 1;
  height: 120px;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}

/* Card */

.card {
  display: flex;
  width: 80%;
  max-width: 720px;
  background-color: rgba(44, 100, 98, 0.8);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  height: 80px;
  flex-direction: column;
  transition: all 0.65s ease-in-out;
  opacity: 0;
  flex: 1 1;
}

.App.promise .card {
  opacity: 1;
}

.card .promise {
  transition: all 0.65s ease-in-out;
  opacity: 1;
}

.card.out .promise {
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}

.card .promise {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-items: center;
  justify-content: center;
}

.card .text {
  margin: 10%;
}

.card .ref {
  margin: 0 10%;
  align-self: flex-end;
  font-size: 1rem;
  font-weight: 400;
}

.card .instructions {
  margin: 16px 10%;
  justify-self: flex-end;
  font-size: 1rem;
  font-weight: 400;
  text-transform: uppercase;
}

/* Mariners Logo */
.App .footer {
  margin-top: 32px;
  width: 80%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.25s ease-out;
  opacity: 0;
  padding-bottom: 120px;
}

.App .footer .logo-mariners-church {
  background-image: url(/static/media/mariners-church.71e80b90.png);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  flex: 1 1;
  max-width: 300px;
  height: inherit;
  margin-right: 32px;
}

.App.home .footer,
.App.promise .footer {
  opacity: 1;
}

.App .footer button {
  width: unset;
  height: auto;
  height: initial;
  padding: 8px 16px;
  font-size: 16px;
}

